import React, {useEffect, useState} from 'react';
import { Route, Link, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './../pages/main.scss';
import logo from '../assets/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { apiUrl } from '../config/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleRight,
  faMagnifyingGlass,
  faBars,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Modal from "react-modal";
import Select from 'react-select';
import Swal from 'sweetalert2';
import Spinner from "react-activity/dist/Spinner";
import TawkChat from './TawkChat';
import offerimg from '../assets/offerimg.jpeg';

export const Header = () => {
  const [sticky, setSticky] = useState("");
  const [open, setOpen] = useState(false);
  const [categorydata, setcategorydata] = useState([]);
  const [coursedata, setcoursedata] = useState([]);
  const [dropdowndisplay, setdropdowndisplay] = useState(false);
  const [handlechangestate, sethandlechangestate] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [coursesoption, setcoursesoption] = useState([]);

  const [showloading, setshowloding] = useState(false); 

  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [selectedcourse, setselectedcourse] = useState("");
  const [message, setmessage] = useState("");
  const [privacycheck, setprivacycheck] = useState(false);
  const [swalProps, setSwalProps] = useState({});


  const [showoffermodal, setshowoffermodal] = useState(false);
  const [offerfullname, setofferfullname] = useState("");
  const [offeremail, setofferemail] = useState("");
  const [offernumber, setoffernumber] = useState("");
  const [offerselectedcourse, setofferselectedcourse] = useState("");
  const [offermessage, setoffermessage] = useState("");

  let navigate = useNavigate();

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 250 ? "is-sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `headerdiv ${sticky} mobhide`;

  const location = useLocation();


  const fetchproductsbyid = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "getallcategories", headers)
      .then((res) => {
        if (res.data.status == "success") {
          // console.log('getallcategories >>', res.data.categorydata);
          var prevdata = res.data.categorydata;
          var newarr = [];
          for(var i = 0; i < prevdata?.length; i++) {
            var newobj = prevdata[i];
            newobj['isshow'] = false;
            newarr.push(newobj);
          }
          setcategorydata(res.data.categorydata);
          setcoursedata(res.data.coursedata);
          var prevcoursedata = res.data.coursedata;
          // console.log('prevcoursedata >>>', prevcoursedata);
          var courseoptionsdata = [];
          for(var i = 0; i < prevcoursedata?.length; i++) {
            var obj = {};
            obj['value'] = prevcoursedata[i].title;
            obj['label'] = prevcoursedata[i].title;
            courseoptionsdata.push(obj);
          }
          setcoursesoption(courseoptionsdata);
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const handlechangeisshow = async(index) => {
    var prevdata = categorydata;
    prevdata[index]['isshow'] = !prevdata[index]['isshow'];
    setcategorydata(prevdata);
    sethandlechangestate(!handlechangestate)
  }

  const handlecourseclick = (item) => {
    console.log('item >>>', item);
    navigate('/courses/'+item.category+'/'+item.slug);
    setOpen(false);
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const closeOfferModal = () => {
    console.log('closeOfferModal >>');
    setshowoffermodal(false);
    sessionStorage.setItem('formSubmitted', 'true');
  }

  const handlechange = (selectedOption) => {
    setselectedcourse(selectedOption)
  }

  const offerhandlechange = (selectedOption) => {
    setofferselectedcourse(selectedOption);
  }

  const handlesubmitregister = async() => {
    if(fullname && email && number && selectedcourse) {
      setshowloding(true);
        var data = {
            fullname: fullname,
            email: email,
            number: number,
            course: selectedcourse?.value,
            message: message
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .post(apiUrl + "registernow", data, headers)
          .then((res) => {
            console.log('res >>>', res.data.data)
            if (res.data.status == "success") {
              Swal.fire({
                title: 'Registered Successfully!',
                text: 'One of our representative will get back to you.',
                icon: 'success',
                customClass: {
                  icon: 'custom-swal-icon' // Apply the custom class
                },
                width: '400px',
                confirmButtonText: 'Ok'
              });
              setfullname("");
              setemail("");
              setnumber("");
              setselectedcourse({});
              setmessage("");
              closeModal();
            } else {
              Swal.fire({
                title: 'Not Registered!!',
                text: 'Something went wrong please try again',
                icon: 'error',
                customClass: {
                  icon: 'custom-swal-icon' // Apply the custom class
                },
                width: '400px',
                confirmButtonText: 'Ok'
              });
            }
          }).catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
          setshowloding(false);
      } else {
      alert('Fill all required details.')
    }
  }

  const handlesubmitofferform = async() => {
    if(offerfullname && offeremail && offernumber && offerselectedcourse) {
      setshowloding(true);
        var data = {
            name: offerfullname,
            email: offeremail,
            number: offernumber,
            course: offerselectedcourse?.value,
            message: offermessage
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .post(apiUrl + "offerformsubmit", data, headers)
          .then((res) => {
            console.log('res >>>', res.data.data)
            if (res.data.status == "success") {
              Swal.fire({
                title: 'YED10',
                text: 'Use this coupon code during checkout',
                icon: 'success',
                customClass: {
                  icon: 'custom-swal-icon' // Apply the custom class
                },
                width: '400px',
                confirmButtonText: 'Ok'
              });
              setofferfullname("");
              setofferemail("");
              setoffernumber("");
              setofferselectedcourse({});
              setoffermessage("");
              closeOfferModal();
            } else {
              Swal.fire({
                title: 'Not Submitted!!',
                text: 'Something went wrong please try again',
                icon: 'error',
                customClass: {
                  icon: 'custom-swal-icon' // Apply the custom class
                },
                width: '400px',
                confirmButtonText: 'Ok'
              });
            }
          }).catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
          setshowloding(false);
      } else {
      alert('Fill all required details.')
    }
  }

  useEffect(() => {
    // console.log('location >>>', location);
    if(location?.pathname?.startsWith('/courses/')) {

    } else {
      window.scrollTo(0, 0);
    }
    
  }, [location]);

  useEffect(() => {
    fetchproductsbyid();
    const isFormSubmitted = sessionStorage.getItem('formSubmitted');
    if (!isFormSubmitted) {
      setshowoffermodal(true);
    }
    
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };

   
  }, []);
    return (
      <>
        <div className={classes}>
          <div className="pagewidth">
            <div className="headerblock">
              <img src={logo} className="mainlog" />
              <nav class="navbar navbar-inverse">
                <ul class="nav navbar-nav">
                  <li>
                    <Link
                      to="/"
                      className={location.pathname == "/" ? "active " : ""}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about-us"
                      className={
                        location.pathname == "/about-us" ? "active " : ""
                      }
                    >
                      About Us
                    </Link>
                  </li>
                  <li class="dropdown">
                    <Link href="#">
                      Courses
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="headericons"
                      />
                    </Link>
                    <div class="dropdown-content">
                      {categorydata?.length > 0
                        ? categorydata?.map((item, index) => {
                            return (
                              <>
                              {
                                index == 0 ?
                                <div key={index} className="dropdown-mainlist" 
                                style={{cursor: 'pointer'}}
                                onClick={() => {navigate("/all-courses")}}
                                >
                                  <p className="dropdown-mainlist-p">
                                  {" "}
                                  {"All Courses"}{" "}
                                  
                                </p>
                                </div> : null
                              }
                                <div key={index} className="dropdown-mainlist">
                                <p className="dropdown-mainlist-p">
                                  {" "}
                                  {item?.categoryname}{" "}
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    className="headericons1"
                                  />
                                </p>
                                <div className="subdropdowncontent">
                                  {coursedata?.length > 0
                                    ? coursedata?.map((item1, index1) => {
                                        if (item.slug == item1.category) {
                                          return (
                                            <div
                                              onClick={() => {
                                                handlecourseclick(item1);
                                              }}
                                              className="subdropdowncontentrow"
                                              key={index1}
                                            >
                                              <p>{item1.title}</p>
                                            </div>
                                          );
                                        }
                                      })
                                    : null}
                                </div>
                              </div>
                              </>
                              
                            );
                          })
                        : null}
                    </div>
                  </li>
                  <li>
                    <Link
                      to="/corporate-training"
                      className={
                        location.pathname == "/corporate-training"
                          ? "active "
                          : ""
                      }
                    >
                      Corporate Training
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/trainers"
                      className={
                        location.pathname == "/trainers" ? "active " : ""
                      }
                    >
                      Trainers
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/contact-us"
                      className={
                        location.pathname == "/contact-us" ? "active " : ""
                      }
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
              <div
                className="header-registernow-btn"
                onClick={() => {
                  openModal();
                }}
              >
                <p>Register Now</p>
              </div>
            </div>
          </div>
        </div>
        {/* mobile menu */}
        <div className="deskhide mobheadermaindiv">
          <div className="pagewidth">
            <div className="mobmenubar">
              <div
                onClick={() => {
                  setOpen(true);
                  console.log("opened");
                }}
                className="mobmenu"
              >
                {/* <img src={Menu} /> */}
                <FontAwesomeIcon icon={faBars} className="mobileheadericons" />
              </div>
              <div className="moblogo">
                <img src={logo} />
              </div>
              <div class="logindiv" style={{width: 20}}>
                {/* <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="mobileheadericons"
                />
                <a class="nav-link" href="/home">
                Login
              </a> */}
              </div>
            </div>
          </div>
          <Drawer
            open={open}
            placement="left"
            onClick={() => setOpen(false)}
            onClose={() => setOpen(false)}
            backdrop={true}
            size={"xs"}
          >
            <Drawer.Header></Drawer.Header>
            <Drawer.Body>
              {/* <Placeholder.Paragraph rows={8} /> */}
              <div className="mobmenu-slider-maindiv">
                <ul class="nav navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className={
                        location.pathname == "/"
                          ? "active nav-link"
                          : "nav-link"
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/about-us"
                      className={
                        location.pathname == "/about-us"
                          ? "active nav-link"
                          : "nav-link"
                      }
                    >
                      About Us
                    </Link>
                  </li>
                  <li class="dropdown nav-item">
                    <Link
                      href="#"
                      className="nav-link"
                      onClick={() => {
                        setdropdowndisplay(!dropdowndisplay);
                      }}
                    >
                      Courses
                      {dropdowndisplay ? (
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="headericons"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="headericons"
                        />
                      )}
                    </Link>
                    <div
                      className={
                        dropdowndisplay
                          ? "dropdown-content d-block"
                          : "dropdown-content d-none"
                      }
                    >
                      {categorydata?.length > 0
                        ? categorydata?.map((item, index) => {
                            return (
                              <>
                              {
                                index == 0 ?
                                <div key={index} className="dropdown-mainlist" 
                                style={{cursor: 'pointer'}}
                                onClick={() => {navigate("/all-courses")}}
                                >
                                  <p className="dropdown-mainlist-p">
                                  {" "}
                                  {"All Courses"}{" "}
                                  
                                </p>
                                </div> : null
                              }
                               <div key={index} className="dropdown-mainlist">
                                <p
                                  className="dropdown-mainlist-p"
                                  onClick={() => {
                                    handlechangeisshow(index);
                                  }}
                                >
                                  {" "}
                                  {item?.categoryname}
                                  {item?.isshow ? (
                                    <FontAwesomeIcon
                                      icon={faAngleDown}
                                      className="headericons1"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faAngleRight}
                                      className="headericons1"
                                    />
                                  )}
                                </p>
                                <div
                                  className={
                                    item?.isshow
                                      ? "subdropdowncontent d-block"
                                      : "subdropdowncontent d-none"
                                  }
                                >
                                  {coursedata?.length > 0
                                    ? coursedata?.map((item1, index1) => {
                                        if (item.slug == item1.category) {
                                          return (
                                            <div
                                              onClick={() => {
                                                handlecourseclick(item1);
                                              }}
                                              className="subdropdowncontentrow"
                                              key={index1}
                                            >
                                              <p>{item1.title}</p>
                                            </div>
                                          );
                                        }
                                      })
                                    : null}
                                </div>
                              </div>
                              </>
                             
                            );
                          })
                        : null}
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/corporate-training"
                      className={
                        location.pathname == "/corporate-training"
                          ? "active nav-link"
                          : "nav-link"
                      }
                    >
                      Corporate Training
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/trainers"
                      className={
                        location.pathname == "/trainers"
                          ? "active nav-link"
                          : "nav-link"
                      }
                    >
                      Trainers
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/contact-us"
                      className={
                        location.pathname == "/contact-us"
                          ? "active nav-link"
                          : "nav-link"
                      }
                    >
                      Contact Us
                    </Link>
                  </li>

                  <li className="nav-item"
                 
                  >
                    <Link
                       onClick={() => {
                        setOpen(false);
                        openModal();
                      }}
                      className={"nav-link"}
                    >Register Now</Link>
                    
                  </li>
                </ul>
              </div>
            </Drawer.Body>
          </Drawer>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          id="registermodal"
        >
          <div className="inpersonmodal">
            <div className="modalheader">
              <p className="modaltitle">Register Now</p>
              <div
                className="modalclosediv"
                onClick={() => {
                  closeModal();
                }}
              >
                <FontAwesomeIcon icon={faXmark} className="coursecloseicons" />
              </div>
            </div>
            <div className="modalbody">
              <div className="registermodalmaindiv">
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Full Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={fullname}
                    onChange={(t) => {
                      setfullname(t.target.value);
                    }}
                    type="text"
                    className="form-control order-summaryinput"
                    placeholder="Enter Full Name"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Email Address <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={email}
                    onChange={(t) => {
                      setemail(t.target.value);
                    }}
                    type="email"
                    className="form-control order-summaryinput"
                    placeholder="Enter Email Address"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Mobile Number <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={number}
                    onChange={(t) => {
                      setnumber(t.target.value);
                    }}
                    type="number"
                    className="form-control order-summaryinput"
                    placeholder="Enter Contact Number"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Course <span style={{ color: "red" }}>*</span>
                  </p>
                  <Select value={selectedcourse} onChange={handlechange} options={coursesoption} className="order-summaryinput" />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">Message (optional)</p>
                  <textarea
                    style={{ height: 80 }}
                    rows={5}
                    value={message}
                    onChange={(t) => {
                      setmessage(t.target.value);
                    }}
                    type="text"
                    className="form-control order-summaryinput"
                    placeholder="Enter Message"
                  ></textarea>
                </div>
                <div className="registerbtn" onClick={
                  !showloading ?
                  handlesubmitregister : null
                } >
                  {
                    showloading ?
                    <Spinner color='#fff' size={15} className='spinnerCenter' /> : 
                    <p>Register Now</p>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Offer Modal */}
        <Modal
          isOpen={showoffermodal}
          onRequestClose={closeOfferModal}
          // style={{padding: 0}}
          className={"offermodal"}
        >
         <div className="offermodalinnerdiv">
          <div className='offermodalinnerrow'>
              <div className='offerimgbg'></div>
              {/* <img src={offerimg} className='offerimg' /> */}
              <div className='offermodalformdiv'>
                 <div
                className="offermodalclosediv"
                onClick={() => {
                  closeOfferModal();
                }}
              >
                <FontAwesomeIcon icon={faXmark} className="coursecloseicons" />
              </div>
            <div className="modalbody">
              <div className="registermodalmaindiv">
                <p className='deskhide yearendofferstext'>Year End Offers</p>
                <div className="learner-inputdiv">
                  
                  <input
                    value={offerfullname}
                    onChange={(t) => {
                      setofferfullname(t.target.value);
                    }}
                    type="text"
                    className="form-control order-summaryinput"
                    placeholder="Full Name *"
                  />
                </div>
                <div className="learner-inputdiv">
                  
                  <input
                    value={offeremail}
                    onChange={(t) => {
                      setofferemail(t.target.value);
                    }}
                    type="email"
                    className="form-control order-summaryinput"
                    placeholder="Email Address *"
                  />
                </div>
                <div className="learner-inputdiv">
                 
                  <input
                    value={offernumber}
                    onChange={(t) => {
                      setoffernumber(t.target.value);
                    }}
                    type="number"
                    className="form-control order-summaryinput"
                    placeholder="Contact Number *"
                  />
                </div>
                <div className="learner-inputdiv">
                 
                  <Select placeholder="Select Course *" value={offerselectedcourse} onChange={offerhandlechange} options={coursesoption} className="order-summaryinput" />
                </div>
                <div className="learner-inputdiv">
                  
                  <textarea
                    style={{ height: 80 }}
                    rows={5}
                    value={offermessage}
                    onChange={(t) => {
                      setoffermessage(t.target.value);
                    }}
                    type="text"
                    className="form-control order-summaryinput"
                    placeholder="Message (optional)"
                  ></textarea>
                </div>
                <div className="registerbtn offerclaimbtn" onClick={
                  !showloading ?
                  handlesubmitofferform : null
                } >
                  {
                    showloading ?
                    <Spinner color='#fff' size={15} className='spinnerCenter' /> : 
                    <p>Get Coupon Code</p>
                  }
                  
                </div>
                <div className='offermodalfootertext'>
                  <p> By providing your contact details you agreed to our<Link to={'/privacy-policy'} target='_blank'> Privacy and Policy.</Link></p>
                </div>
              </div>
            </div>
              </div>
          </div>
           
          </div>
        </Modal>


        <TawkChat />
      </>
    );
}