import React, { useEffect, useState,useCallback,
    useRef,
    StrictMode, } from "react";
    import axios from "axios";
  import { apiUrl } from "../config/Config";
    import { useNavigate } from "react-router-dom";
    import { useParams } from "react-router-dom";
    import { Header } from "../components/Header";
    import { Footer } from "../components/Footer";
    import thankyouimg from "../assets/payment/thankyou.png";

const CCAvenueSuccess = () => {
    const navigate = useNavigate();
    const { transid } = useParams();
    const [showloading, setshowloading] = useState(false);

    useEffect(() => {
        const verifyPayment = async () => {
          try {
          const data = {
              queryid: transid
          };
          const headers = {
            headers: {
              "Content-Type": "application/json",
            },
          };
    
            const response = await axios.post(apiUrl + "v-cc-payment", data, headers).then((resp) => {
              // console.log('resp >>', resp?.data)
              if(resp?.data?.status == "success") {
                
              } else {
                navigate('/cart');
              }
            }).catch((err) => {
              console.log('err >>', err)
            });
          } catch (error) {
            console.error("Error verifying payment:", error);
            navigate('/cart');
          }
        };
    
        verifyPayment();
      }, [navigate]);


    return (
      <div className="cartheader">
      <Header />
      <div className="course-details-topborder"></div>
      <div className="pagewidth">
        <div className="thankyousection">
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="thankyouhead">Thank You for Enrolling!</p>
              <p className="thankyoudesc">We're thrilled to have you join our community of learners dedicated to advancing their skills and knowledge. Your enrollment is confirmed, and you're one step closer to achieving your professional goals.</p>
              <p className="thankyoudesc thankyoudesc-needhelp">Need Help ?</p>
              <p className="thankyoudesc">If you any questions or need assistance, our support team os here to help. Feel free to contact us at support@upskills.com or visit our Help Center.</p>
            </div>
            <div className="col-12 col-md-6">
                <img src={thankyouimg} className="thankyouimg" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
    );
}

export default CCAvenueSuccess